<!-- 供应商明细 -->
<template>
  <div class="supplierDetail">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="status" placeholder="选择状态" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="supplierName" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="applyNum" placeholder="搜索申请单号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyNum" label="申请单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyUserName" label="供应商名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplierCode" label="供应商编号"
            width="150"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountType" label="账户类型">
            <template slot-scope="scope">
              <!-- 1支付宝，2银行卡 -->
              <div>{{ scope.row.accountType == 1 ? '支付宝' : '银行卡' }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountName" label="真实姓名"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountNum" label="账号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="availableAmount"
            label="可提现金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyAmount" label="提现金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="申请时间" align="center"
            width="180"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="" label="状态">
            <template slot-scope="scope">
              <div :style="{ color: stateColor(scope.row) }">{{ stateTransformation(scope.row) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200" min-width="200" fixed="right">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 0">
                <el-button type="primary" @click="handlePass(scope.row)">通过</el-button>
                <el-button type="danger" plain @click="handleNotGo(scope.row)">不通过</el-button>
              </div>
              <div v-else>
                <el-button type="primary" plain @click="handleDetails(scope.row)">查看</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
    <!-- 不通过原因 -->
    <el-dialog title="审核不通过" :visible.sync="submitVisible" :before-close="onCancel">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left"
        label-width="130px">
        <el-form-item label="不通过原因" prop="loseContent">
          <el-input type="textarea" v-model="examineForm.loseContent"
            :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: [{
        value: '0',
        label: '待审核'
      }, {
        value: '1',
        label: '已转账'
      }, {
        value: '2',
        label: '审核失败'
      }, {
        value: '3',
        label: '转账失败'
      },],
      status: '',//状态
      dataValue: '', // 时间区间
      supplierName: '', // 搜索供应商名称
      applyNum: '', // 申请单号
      submitVisible: false,//不通过原因弹窗
      submitLoding: false,
      notGoId: '',//不通过id
      examineForm: {
        loseContent: ''
      },//不通过原因auditMsg
      rules: {
        loseContent: [
          { required: true, message: "请输入不通过原因", trigger: "submit" },
        ],
      },
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getRequestList();
  },
  methods: {
    // 获取列表
    getRequestList() {
      this.loading = true;
      let params = {
        supplierName: this.supplierName || null, // 供应商名称
        applyNum: this.applyNum || null, // 申请单号
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        status: this.status || null,//状态：0待审核，1审核通过（已转账），2审核不通过，3转账失败
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }
      this.$axios.get(this.$api.setSupplierList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 审核申请
    getSignature(id, status) {
      this.$axios
        .put(this.$api.setSupplierListDetAuditing, {
          id: id,
          auditMsg: this.examineForm.loseContent || null,
          status: status,//状态：1审核通过（已转账）2审核不通过，
        })
        .then((res) => {
          const { code, desc } = res.data
          if (code == 100) {
            this.$message.success(desc);
            this.getRequestList();
            this.submitLoding = false;
            this.onCancel();
          }

        }).catch(() => {
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getRequestList();
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.status = value;
      this.getRequestList();
    },
    // 时间搜索
    handleSelectData(value) {
      this.clearData();
      this.dataValue = value;
      this.getRequestList();
    },
    // 点击查看
    handleDetails(row) {
      this.$router.push({ path: '/settlement/detail/supplierWithDet', query: { id: row.id } })
    },
    // 点击不通过
    handleNotGo(row) {
      this.submitVisible = true
      this.notGoId = row.id
    },
    // 点击通过
    handlePass(row) {
      this.$confirm('提示', {
        title: '提示',
        message: '确认审核通过吗，是否继续 ?',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        this.getSignature(row.id, 1)//请求审核通过
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消审核!'
        });
      })
    },
    // 状态转换
    stateTransformation(row) {
      switch (String(row.status)) {
        case '0':
          return '待审核'
        case '1':
          return '已转账'
        case '2':
          return '审核失败'
        default:
          return '转账失败'
      }
    },
    // 状态颜色
    stateColor(row) {
      switch (String(row.status)) {
        case '0':
          return 'red'
        case '2':
          return 'red'
        case '3':
          return 'red'
        default:
          return ''
      }
    },
    // 关闭对话框
    onCancel() {
      this.submitVisible = false;
      this.examineForm = {};
      this.notGoId = ''
    },
    // 提交不通过
    submitForm(formName) {
      this.submitLoding = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getSignature(this.notGoId, 2)//请求审核通过
          // this.$axios
          //   .put(this.$api.setSupplierListDetAuditing, {
          //     id: 8,
          //     auditMsg: this.examineForm.loseContent || null,
          //     status: 2,//状态：2审核不通过，
          //   })
          //   .then((res) => {
          //     this.$message.success(res.data.desc);
          //     // this.getRequestList();
          //     this.submitLoding = false;
          //     this.onCancel();
          //   })
          //   .catch((err) => {
          //     this.$message.error(err.data.desc);
          //   });
        } else {
          this.submitLoding = false;
          this.$message.error("提交失败");
          return false;
        }
      });
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getRequestList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getRequestList();
    },
  }

}



</script>

<style scoped lang="scss">
.supplierDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
